module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!dataArr) var dataArr = [ '1', '2' ]
;
__p += '\r\n<div class="comp-tab">\r\n  ';
 for (var i = 0; i < dataArr.length; i++) { ;
__p += '\r\n    <a class="comp-tab-item ' +
((__t = ( i === 0 ? 'active' : '' )) == null ? '' : __t) +
'" href="#">\r\n      <p>' +
((__t = ( dataArr[ i ] )) == null ? '' : __t) +
'</p>\r\n    </a>\r\n  ';
 } ;
__p += '\r\n</div>';

}
return __p
}