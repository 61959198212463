import './index.scss'

// footer 固定效果
!(function () {
  let $rootEl = $('.comp-root')
  let $footerEl = $('.comp-root-footer')
  let setPaddingBottom = (function fn () {
    $rootEl.css(
      'padding-bottom',
      $footerEl.css('height')
    )
    return fn
  })()
  
  $(window).on('resize', setPaddingBottom)
})()

// go top
!(function () {
  $('.comp-footer-top').click(function (e) {
    e.preventDefault()
    $('html, body').animate({
      scrollTop: 0
    }, 700)
  })
})()