module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!dir) var dir = 'right'
;
__p += '\r\n<div class="comp-search">\r\n  ';
 if (dir === 'left') { ;
__p += '\r\n  <button class="comp-search-btn iconfont icon-search"></button>\r\n  ';
 } ;
__p += '\r\n  <input class="comp-search-input" type="text" placeholder="请输入楼盘名或关键字查询">\r\n  ';
 if (dir === 'right') { ;
__p += '\r\n  <button class="comp-search-btn iconfont icon-search"></button>\r\n  ';
 } ;
__p += '\r\n</div>';

}
return __p
}