module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!theme) var theme = 'green'
;
__p += '\r\n\r\n<div class="comp-control-btn ' +
((__t = ( theme )) == null ? '' : __t) +
'">\r\n  <i class="comp-control-btn-item iconfont icon-arrow-left prev"></i>\r\n  <i class="comp-control-btn-item iconfont icon-arrow-right next"></i>\r\n</div>';

}
return __p
}