module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a class="comp-project" href="#">\r\n  <div class="comp-project-media">\r\n    <b class="comp-project-media-pic" style="background-image: url(' +
((__t = ( require('./images/comp-project.jpg') )) == null ? '' : __t) +
')"></b>\r\n    <div class="comp-project-media-footer">\r\n      <p>小高层8500元/㎡</p>\r\n      <p>在建、在售</p>\r\n    </div>\r\n  </div>\r\n  <div class="comp-project-body">\r\n    <h3 class="comp-project-title">\r\n      海信 • 君澜\r\n    </h3>\r\n    <p class="comp-project-subtitle">\r\n      170-263㎡麦岛升级收官之作\r\n    </p>\r\n    <i class="comp-project-line"></i>\r\n    <div class="comp-project-desc">\r\n      <p><i class="iconfont icon-position"></i>崂山区香港东路108号</p>\r\n      <p><i class="iconfont icon-phone"></i>0532-88007777</p>\r\n    </div>\r\n  </div>\r\n</a>';

}
return __p
}