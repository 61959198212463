module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!dir) var dir = 'x';

var coverPath = dir === 'x' ? require('./images/article-x.jpg') : require('./images/article-y.jpg')
;
__p += '\r\n<a class="comp-article ' +
((__t = ( dir )) == null ? '' : __t) +
'" href="#">\r\n  <div class="comp-article-media">\r\n    <div class="finish">已结束</div>\r\n    <b class="comp-article-media-inner" style="background-image: url(' +
((__t = ( coverPath )) == null ? '' : __t) +
');"></b>\r\n  </div>\r\n  <div class="comp-article-body">\r\n    <time class="comp-article-body-time">\r\n      活动日期：10月26日上午9：00-12：00\r\n    </time>\r\n    <h3 class="comp-article-body-title">\r\n      文墨盛启 御冠墨城 | 海信·文墨里即将盛大开盘\r\n    </h3>\r\n    <p class="comp-article-body-desc">\r\n      2019年10月21日，海信地产以拍卖起始价2.4988亿元成功竞得平度汽车站南项目东地块。汽车站南项目东地块占地69亩，居住兼容商业、商务用地，容积率2.5，规划地上建筑面积11万平方米。\r\n    </p>\r\n  </div>\r\n</a>';

}
return __p
}