module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header class="comp-header">\r\n  <div class="comp-header-body">\r\n    <div class="container-fluid">\r\n      <div class="container-fluid">\r\n        <div class="comp-header-wrapper">\r\n          <div class="comp-header-logo">\r\n            <a href="#">\r\n              <img class="comp-header-logo-inner" src="' +
((__t = ( require('./images/header-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n          </div>\r\n          <div class="comp-header-nav">\r\n            <a class="comp-header-nav-item" href="#">\r\n              首页\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              项目展示\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              新闻中心\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              客户服务\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              公司介绍\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              诚聘英才\r\n            </a>\r\n            <a href="" class="comp-header-nav-item">\r\n              联系我们\r\n            </a>\r\n          </div>\r\n          <a class="comp-header-pos" href="#select-city" data-fancybox>\r\n            <i class="iconfont icon-position"></i>\r\n            <p>青岛</p>\r\n            <i class="iconfont icon-arrow-bottom"></i>\r\n          </a>\r\n          <div class="comp-header-hamburger">\r\n            <div class="hamburger hamburger-1">\r\n              <div></div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="comp-header-city" id="select-city">\r\n    <h3 class="comp-header-city-title">\r\n      选择城市\r\n    </h3>\r\n    <div class="comp-header-city-list">\r\n      <div class="row space-x-2 space-y-4">\r\n        <div class="col">\r\n          <a class="comp-header-city-item active" href="#">\r\n            <p>青岛</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-1.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>济南</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-2.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>烟台</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-3.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>潍坊</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-4.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>威海</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-5.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n      </div>\r\n      <div class="row space-x-2 space-y-4">\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>东营</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-6.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>深圳</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-7.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>马鞍山</p>\r\n            <img src="' +
((__t = ( require('./images/header-city-8.png') )) == null ? '' : __t) +
'" alt="">\r\n          </a>\r\n        </div>\r\n        <div class="col">\r\n          <a class="comp-header-city-item" href="#">\r\n            <p>敬请期待</p>\r\n            <!--<img src="' +
((__t = ( require('./images/header-city-1.png') )) == null ? '' : __t) +
'" alt="">-->\r\n          </a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</header>';

}
return __p
}