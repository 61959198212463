module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!theme) var theme = 'white'
;
__p += '\r\n\r\n<div class="comp-control-pagination ' +
((__t = ( theme )) == null ? '' : __t) +
'">\r\n  <i class="active"></i>\r\n  <i class=""></i>\r\n  <i class=""></i>\r\n</div>';

}
return __p
}