module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = '公司介绍'
if (!picPath) var picPath = require('./images/banner-intro.jpg')
;
__p += '\r\n\r\n<section class="comp-banner">\r\n  <div class="comp-banner-body">\r\n    <div class="container-fluid">\r\n      <img class="comp-banner-pic" src="' +
((__t = ( picPath )) == null ? '' : __t) +
'" alt="">\r\n      <h3 class="comp-banner-title">\r\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\r\n      </h3>\r\n    </div>\r\n  </div>\r\n  <div class="comp-banner-nav">\r\n    <div class="container-fluid">\r\n      <div class="comp-banner-nav-wrapper">\r\n        <a class="comp-banner-nav-item active" href="#">公司简介</a>\r\n        <a class="comp-banner-nav-item" href="#">企业文化</a>\r\n        <a class="comp-banner-nav-item" href="#">企业荣誉</a>\r\n        <a class="comp-banner-nav-item" href="#">设计院</a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}