import './index.scss'

// input textarea placeloader 鼠标移入就消失
$('input, textarea')
  .on('focus', (e) => {
    let $el = $(e.target)
    $el.data('placeholder', $el.attr('placeholder'))
    $el.attr('placeholder', '')
  })
  .on('blur', (e) => {
    let $el = $(e.target)
    $el.attr('placeholder', $el.data('placeholder'))
  })